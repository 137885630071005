import React from "react";
import { Link } from "react-router-dom";
import { ContentBox } from './Content';
import { Component } from "../../components/Component";
import "./style.css";

export const Screen7 = () => {
  return (
    <div className="screen-7">
      <div className="desktop-3">
        <Link to="/desktop-4">
          <img className="logo-3" alt="Logo" src="/img/logo_icon.png" />
        </Link>
        <div className="navbar">
          <Link className="text-wrapper-79" to="/">Home</Link>
            <Link className="text-wrapper-80" to="/research-details">
              Research Details
            </Link>
            <Link className="text-wrapper-81" to="/resources">
              Resources
            </Link>
            <Link className="text-wrapper-82" to="/contact-us">
              Contact
            </Link>
            <div className="rectangle-14" />
        </div>
        <div className="overlap-24">
          <a
            className="button-7"
            href="https://docs.google.com/forms/d/e/1FAIpQLSexb04G0ML2hToAahd_Y23tebcAOXjDRKrR0k7_EC4jP0mNSQ/viewform?usp=header"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="overlap-group-17">
              <div className="text-wrapper-83">Register</div>
            </div>
          </a>
          <div className="text-wrapper-84">2025 Research Theme</div>
          <div className="text-wrapper-85">திருக்குறள் மற்றும் தமிழர் பண்பாடு</div>
        </div>
        <div className="group-79">
          <div className="text-wrapper-86">Research Topics</div>
          <div className="group-80">
            <div className="overlap-group-18">
              <img className="rectangle-15" alt="Rectangle" src="/img/rectangle-15-4.svg" />
              <img className="image-40" alt="Image" src="/img/image-25-1.png" />
              <img className="image-41" alt="Image" src="/img/image-23-1.png" />
              <p className="text-wrapper-87">
              வள்ளுவம் காட்டும் அறிவு<br />
              வள்ளுவம் காட்டும் கோன்மை<br />
              வள்ளுவம் காட்டும் வாய்மை<br />
              வள்ளுவம் காட்டும் புகழ்<br />
              வள்ளுவம் காட்டும் துறவு<br />
              வள்ளுவம் காட்டும் ஊழ்<br />
              வள்ளுவம் காட்டும் கல்வி<br />
              வள்ளுவம் காட்டும் அன்பு<br />
              வள்ளுவம் உணர்த்தும் நிலையாமை<br />
              வள்ளுவம் உணர்த்தும் நற்பண்புகள்<br />
              வள்ளுவம் காட்டும் ஈகை<br />
              வள்ளுவம் உணர்த்தும் மன உறுதி<br />
              வள்ளுவம் காட்டும் கோபம்<br />
              திருக்குறள் காட்டும் பகைவெறி எதிர்ப்பியல்<br />
              திருக்குறளில் கடல்<br />
              வள்ளுவம் காட்டும் உளவு<br />
              திருக்குறள் காட்டும் செல்வம்<br />
              திருக்குறள் காட்டும் மானுடவியல் சிந்தைனகள்<br />
              வள்ளுவம் காட்டும் வாழ்வியல் நெறிமுறைகள்<br />
              வள்ளுவம் காட்டும் உறவும் பகையும்<br />
              மற்றும் பல
              </p>
            </div>
          </div>
          <div className="group-81">
            <div className="overlap-25">
              <img className="rectangle-15" alt="Rectangle" src="/img/rectangle-16-4.svg" />
              <p className="text-wrapper-88">
              வள்ளுவம் காட்டும் அறம்<br />
              வள்ளுவம் காட்டும் தெய்வம்<br />
              வள்ளுவம் காட்டும் அறிவியல்<br />
              வள்ளுவம் காட்டும் நுண்ணறிவு<br />
              திருக்குறளில் உவைமகள்<br />
              வள்ளுவம் காட்டும் நட்பு<br />
              திருக்குறளில் விலங்குகள்<br />
              வள்ளுவம் உணர்த்தும் கற்பு<br />
              வள்ளுவம் உணர்த்தும் நன்றி<br />
              வள்ளுவம் உணர்த்தும் ஒழுக்கம்<br />
              திருக்குறள் காட்டும் அரசியல் முறைகள்<br />
              திருக்குறள் உளவியல் நோக்கு<br />
              திருக்குறளில் குடும்பமும் மனித உறவுகளும்<br />
              திருக்குறளில் சமூகவியல் சிந்தைனகள்<br />
              திருக்குறள் காட்டும் அரசியல் நெறிமுறைகள்<br />
              திருக்குறள் காட்டும் தமிழர் மெய்யியல்<br />
              திருக்குறளும் பெண்களும்<br />
              திருக்குறளும் காட்டும் பொதுவுடைமை<br />
              திருக்குறளில் சுற்றுச்சூழல் சிந்தைனகள்<br />
              வள்ளுவம் உணர்த்தும் முயற்சி
              </p>
              <img className="image-42" alt="Image" src="/img/image-24-1.png" />
              <img className="image-43" alt="Image" src="/img/image-22-1.png" />
            </div>
          </div>
          <p className="text-wrapper-89">2025 தமிழ் ஆராய்ச்சி சில தலைப்புகள் - மாதிரிகள்</p>
        </div>
        <div className="group-82">
          <div className="text-wrapper-90">Who can participate</div>
          <p className="text-wrapper-91">Tamil Students all over the world</p>
          <div className="group-83">
            <div className="group-84">
              <div className="img-wrapper">
                <img className="image-44" alt="Image" src="/img/image-43.png" />
              </div>
              <p className="element-7">
                <span className="text-wrapper-92">நிலை 2: </span>
                <span className="text-wrapper-93">
                  வயது 11 - 17
                </span>
              </p>
            </div>
            <div className="group-85">
              <div className="overlap-26">
                <div className="ellipse-9" />
                <img className="image-45" alt="Image" src="/img/image-44.png" />
              </div>
              <p className="element-8">
                <span className="text-wrapper-92">நிலை 1:&nbsp;&nbsp;</span>
                <span className="text-wrapper-94">வயது 5 - 10</span>
              </p>
            </div>
          </div>
        </div>
        <div className="group-86">
          <div className="overlap-27">
            <img className="rectangle-16" alt="Rectangle" src="/img/rectangle-27.svg" />
            <p className="thirukkural-research-3">
              - Complete Registration using registration link<br /><br />
              - Select Research Topic from the list<br /><br />
              - Select 75 Students based on their Thirukkural abstract submission.
            </p>
            <div className="text-wrapper-95">Phase 1</div>
          </div>
          <div className="overlap-28">
            <img className="rectangle-17" alt="Rectangle" src="/img/rectangle-27.svg" />
            <p className="onboarding-teachers-2">
            Onboarding Students <br /><br />
              - Grading / Reviewing Students weekly Google Slides ( 10 assignments / Google Slides)  <br /><br />
              - Guide Students to create the Poster Presentation              <br /><br />
              - Reviewing / Evaluate - 1st level : Thirukkural research project audio / poster boards - Google Slides.
            </p>
            <div className="text-wrapper-96">Phase 2</div>
          </div>
          <div className="overlap-29">
            <img className="rectangle-17" alt="Rectangle" src="/img/rectangle-27.svg" />
            <p className="thirukkural-research-4">
            - International Tamil Conference on April  2025 Chicago, USA : Evaluate 2nd level and select research papers.
              <br />
              <br />             
            </p>
            <img className="image-46" alt="Image" src="/img/mainpage_img.png" />
            <div className="text-wrapper-97">Phase 3</div>
          </div>
          <div className="text-wrapper-98">Research Phases</div>
          
        </div>
        <ContentBox />
        <Component className="component-1-instance" href="mailto:internationaltamilfair@gmail.com" />
        <div className="overlap-30">
          <div className="overlap-31">
            <div className="ellipse-10" />
            <img className="image-47" alt="Image" src="/img/image-19.png" />
            <img className="image-48" alt="Image" src="/img/image-30.png"/>
          </div>
          <div className="text-wrapper-99">திருக்குறள் மற்றும் தமிழர் பண்பாடு</div>
          <a
            className="button-8"
            href="https://docs.google.com/forms/d/e/1FAIpQLSexb04G0ML2hToAahd_Y23tebcAOXjDRKrR0k7_EC4jP0mNSQ/viewform?usp=header"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="overlap-32">
              <div className="text-wrapper-100">Register</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
